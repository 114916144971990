import React from 'react';
import { 
    Flex,
    useMediaQuery, 
    useTheme,
    Text,
    Image
} from "@chakra-ui/react";

import ButtonBrochure from './ButtonBrochure';
import ButtonCita from './ButtonCita';

import "../styles/fondo.css";

const Fondo = ({ img, title  }) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/folleto.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/calendario.svg' }
    ];

    return ( 
        <Flex marginBottom={"4rem"}>
            <div style={{ backgroundImage: `url('${img}')`, alignItems: "center", backgroundSize: 'cover', backgroundPosition: 'center' }} className='fondo-berilio'>
                <Flex textAlign={"center"} alignItems={"center"} width={"70%"} justifyContent={"center"} paddingBottom={"2rem"}>
                    <Text padding={isGreaterThanMd ? "1rem 1.5rem" : "0.5rem"} color={"#FFFFFF"} fontWeight={"bold"} fontSize={isGreaterThanMd ? '4xl' : '3xl'} letterSpacing={'1px'}>{title}</Text>
                </Flex>
                <Flex justifyContent={"center"} display={isGreaterThanMd?'flex':'grid'} justifyItems={isGreaterThanMd?'':'center'}>
                    <Flex marginRight={isGreaterThanMd?"1.5rem":0} mb={isGreaterThanMd?0:4} flexDirection={"column"} alignItems={"center"}>
                        <Flex marginBottom={"1rem"} textAlign={"center"}>
                            <Image src={icons[0].imageUrl} boxSize={isGreaterThanMd ? 12 : 9} marginRight={"18px"} />
                            <Text lineHeight={"initial"} color={"#FFFFFF"} fontSize={isGreaterThanMd ? 'xl' : 'lg'} fontWeight={"light"}>Todo el espacio que <br />necesitas para vivir</Text>
                        </Flex>
                        <Flex>
                            <ButtonBrochure />
                        </Flex>
                    </Flex>
                    <Flex marginLeft={isGreaterThanMd?"1.5rem":'inherit'} flexDirection={"column"} alignItems={"center"}>
                        <Flex marginBottom={"1rem"} textAlign={"center"}>
                            <Image src={icons[1].imageUrl} boxSize={isGreaterThanMd ? 12 : 9} marginRight={"30px"} />
                            <Text lineHeight={"initial"} color={"#FFFFFF"} fontSize={isGreaterThanMd ? 'xl' : 'lg'} fontWeight={"light"}>Ven a ver las vistas <br/>espectaculares</Text>
                        </Flex>
                        <Flex>
                            <ButtonCita />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        </Flex>
    );
}
 
export default Fondo;