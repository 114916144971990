import React from "react";
import { Link, useDisclosure, useMediaQuery, useTheme } from "@chakra-ui/react";
import ModalContacto from './ModalContacto';

const ButtonCita = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <>
            <Link 
                borderRadius={"18px"} 
                width={isGreaterThanMd ? "232px" : "190px"}
                color={"#FFFFFF"} 
                backgroundColor={"#26B8CE"}
                textAlign={"center"}
                fontSize={isGreaterThanMd ? "16px" : "14px"}
                px={4} py={2}
                fontWeight={"bold"}
                _hover={{ bg: '#E6E6E6' }}
                onClick={onOpen}>Agendar cita</Link>
            {isOpen ?
                <ModalContacto 
                    isOpen={isOpen} 
                    onClose={onClose}
                    title={"Agendar Cita"}
                    thankyoupage={"thankyoupage-agenda-cita"}
                    idForm={"40c90165-f16c-4511-b24f-a614e3202844"} /> : null
            }
        </>
    );
}
 
export default ButtonCita;