import React from 'react';
import { Flex, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import "../styles/mapa.css";

const Ubicacion = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/mapa.svg' }
    ];

    return ( 
        <>
            <Flex id='ubicacion' className='section-mapa' flexDirection={"column"} textAlign={"center"} color={"#545454"}>
                <Text marginBottom={"5px"} fontWeight={"700"} fontSize='2xl'>Ubicación ideal entre la ciudad y el bosque</Text>
                <Text fontSize='md' fontWeight={"light"} marginBottom={"1rem"}>
                    Calzada Federalistas 4345, Esq. Paseo de las Misiones, colonia Real de Valdepeñas,<br/> CP 45130. Zapopan, Jal ¡A 5 min. de Periférico Norte!
                </Text>
                <img src={images[0].imageUrl} style={{ width: isGreaterThanMd ? "100%" : "80%" }} 
                    width='500' height='500' alt='mapa' 
                    onClick={()=>{window.open('https://g.page/miraltoresidencial?share', '_blank')}}/>
            </Flex>
        </>
    );
}
 
export default Ubicacion;
