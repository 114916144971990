import React from 'react';
import { Flex, Stack, Text, Link, Image } from '@chakra-ui/react';
import "../styles/footer.css";

const Footer = () => {

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/txt-un-lugar.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/facebook.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/instagram.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/construvida-logo.svg' }
    ];

    return ( 
        <Flex flexDirection={"column"} backgroundColor={"#F3F3F3"}>
            <Flex padding={"3rem"} paddingBottom={"0px"}>
                <Flex className='footer-logos' width={"33%"} height={"fit-content"}>
                    <Image src={icons[3].imageUrl} boxSize='250px' style={{ height: "80px" }} alt='construvida'/>
                </Flex>
                <Flex className='footer-copyright-top' width={"33%"}>
                    <Image src={icons[0].imageUrl} boxSize='250px' style={{ height: "40px" }} alt='Icon título'/>
                    <Text fontSize='sm' fontWeight={"light"}>los mejores atardeceres y amaneceres desde tu propio depa</Text>
                </Flex>
                <Flex className='footer-iconos' width={"33%"} height={"fit-content"} justifyContent={"end"}>
                    <Stack justifyContent={"end"} direction='column'>
                        <Flex>
                            <Text fontSize='sm' fontWeight={"bold"} color={"#545454"}>Síguenos en redes sociales</Text>
                        </Flex>
                        <Flex justifyContent={"center"}>
                            <Link href='https://www.facebook.com/MiraltoVista' isExternal>
                                <img style={{ marginRight: "1rem" }} src={icons[1].imageUrl} width='23' height='21' alt='Facebook'/>
                            </Link>
                            <Link href='https://instagram.com/miraltovista?igshid=YmMyMTA2M2Y=' isExternal>
                                <img src={icons[2].imageUrl} width='23' height='21' alt='Instagram'/>
                            </Link>
                        </Flex>
                    </Stack>
                </Flex>
            </Flex>
            <Flex justifyContent={"center"} marginTop={"2rem"} backgroundColor={"#E1E1E1"}>
                <Flex textAlign={"center"} justifyContent={"center"} padding={"0.5rem"}>
                    <Text fontSize='md' width={"100%"} fontWeight={"light"}>
                        Copyright &#169; 2022 &#169; All Rights Reserved - Powered by Grupo guia
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default Footer;