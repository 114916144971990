import React from 'react';
import { Flex, useMediaQuery, useTheme, Text } from '@chakra-ui/react';

const Recorrido = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <Flex display="grid" justifyItems={"center"} marginBottom={"2rem"}>
            <Flex display="grid" justifyItems={"center"}>
                <Text fontWeight={"700"} fontSize={"28px"} color={"#545454"}>Recorrido virtual</Text>
            </Flex>
            <Flex id='recorrido' marginTop={"2rem"} width={isGreaterThanMd?'60%': '100%'}>
                <iframe title='myFrame' style={{margin: 'auto'}} width={isGreaterThanMd?"100%":"90%"} height={isGreaterThanMd?"550":"650"} src="https://eckenvt.com/miralto-vista/?embedded" frameBorder="0" allow="vr"
                allowFullScreen="allowfullscreen"></iframe>
            </Flex>
        </Flex>
    );
}
 
export default Recorrido;