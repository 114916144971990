import React, { useState, useEffect } from 'react';
import { 
    Flex, 
    Text,  
    useMediaQuery, 
    useTheme,
    Image,
    Box
} from '@chakra-ui/react';

import "../styles/styled.css";
import "../styles/presentacion.css";

import Nav from './Nav';

import { MarkDownSection } from './Section';
import { Carousel } from './Carousel/Carousel';
//import iconArrowLeft from '../assets/icons/icon-arrow-left.svg';
//import iconArrowRight from '../assets/icons/icon-arrow-right.svg';
//import iconWpp from '../assets/icons/icon-btn-wssp.svg';

const Presentacion = (props) => {
    const [current, setCurrent] = useState(0);
    const [dotClicked, setDotClicked] = useState(false);
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [screenSize, getDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });
    
    const setDimensions = () => {
        getDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/txt-un-lugar.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/txt-un-lugar-blanco.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/ubi-black.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/ubi-white.svg' }
    ];

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/banner1.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/banner2.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/banner3.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/banner4.webp' }
    ];

    const slides = [
        {
            key: "hero1",
            imageUrl: images[0].imageUrl,
            title: "conectado con la ciudad y la naturaleza.<br/><br/>Departamentos de 3 recámaras desde <br/>105m² en preventa, desde $3,525,000*",
            imgTitle: icons[0].imageUrl,
            imgUbi: icons[2].imageUrl,
            color: "#545454"
        },
        {
            key: "hero2",
            imageUrl: images[1].imageUrl,
            title: "los mejores atardeceres con vistas a la <br/>ciudad y el bosque, desde el área verde <br/>o tu departamento.<br/><br/>Departamentos de 3 recámaras desde <br/>105m² en preventa, desde $3,525,000*",
            imgTitle: icons[1].imageUrl,
            imgUbi: icons[3].imageUrl,
            color: "#fff"
        },
        {
            key: "hero3",
            imageUrl: images[2].imageUrl,
            title: "seguro con tus hijos, área infantil, ludoteca, <br/>ingreso controlado, vigilancia 24/7, y mucho más.<br/><br/>Departamentos de 3 recámaras desde <br/>105m² en preventa, desde $3,525,000*",
            imgTitle: icons[1].imageUrl,
            imgUbi: icons[3].imageUrl,
            color: "#fff"
        },
        {
            key: "hero4",
            imageUrl: images[3].imageUrl,
            title: "tiempo con los que más quieres. Con gym, cancha <br/>de usos múltiples, puerto de bicicletas, sports bar, <br/>alberca y mucho más.<br/><br/>Departamentos de 3 recámaras desde <br/>105m² en preventa, desde $3,525,000*",
            imgTitle: icons[1].imageUrl,
            imgUbi: icons[3].imageUrl,
            color: "#fff"
        },
    ];

    useEffect(() => {
        if (!dotClicked) {
          const intervalId = setInterval(() => {
            if (current === slides.length-1) {
              setCurrent(0);
            } else {
              setCurrent(current + 1);
            }
          }, 5000);
        
          return () => clearInterval(intervalId);
        }
    }, [dotClicked, current, slides.length]);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
    
        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, [screenSize]);

    return ( 
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            <Box w="full" mx="auto" maxW="3x1" {...props} paddingInline="0"  position="relative">
                <Carousel current={current}>
                    { slides.map((slide) => (
                        <Flex
                        w="100%"
                        h="100vh"
                        id="fondo"
                        backgroundImage={`url(${slide.imageUrl})`}
                        backgroundSize="cover"
                        backgroundPosition="center center"
                        backgroundRepeat="no-repeat"
                        backgroundColor="gray.100"
                        position="relative"
                        p="0"
                        borderRadius="10px">
                            <Flex color={"#FFFFFF"} height='100%' w='100%' backgroundColor={isGreaterThanMd ? 'transparent': '#0000002e'}></Flex>
                            <Flex
                                justifyContent="end"
                                alignItems={"center"}
                                height="100vh"
                                paddingLeft={isGreaterThanMd ? '0px' : '6rem'}
                                position="absolute"
                                top={"2"}
                                right="16">
                                <Flex flexDirection="column" paddingRight={isGreaterThanMd ? 10 : 0} color={"#fff"}>
                                    <Image src={slide.imgTitle} style={{ width: isGreaterThanMd ? "472px" : "350px", height: "91px" }} alt='Icon título'/>
                                    <Text fontWeight={"light"} className='txt-1' color={slide.color} fontSize={isGreaterThanMd ? '2xl' : 'lg'}><MarkDownSection>{slide.title}</MarkDownSection></Text>
                                    <Image src={slide.imgUbi} style={{ height: "40px", width: "120px" }} alt='Icon título'/>
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Carousel>
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="end"
                    height="100vh"
                    position="absolute"
                    bottom="8"
                    w="100%"
                    left="0"
                    right="0"
                    paddingRight={"1.5rem"}
                >
                    {slides.map((item, index) => (
                    <Text
                        key={`dot-${index}`}
                        onClick={()=> {
                        setDotClicked(!dotClicked);
                        setCurrent(index);
                        }}
                        marginLeft="10px"
                        cursor="pointer"
                        fontSize="20px"
                        color={ (index === current) ? "#C3C3C3" : "#595959"}
                    >
                        &#9679;
                    </Text>
                    ))}
                </Flex>
            </Box>
        </>
    );
}
 
export default Presentacion;