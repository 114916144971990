import React from 'react';
import { Flex } from "@chakra-ui/react";
import "../styles/mapa.css";

const Prototipado = () => {

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/prototipo.webp' }
    ];

    return (
        <>
            <Flex id='master-plan' className='section-mapa' flexDirection={"column"} textAlign={"center"} color={"#545454"}>
                <img src={images[0].imageUrl} style={{ width: "100%" }} width='500' height='500' alt='mapa'/>
            </Flex>
        </>    
    );
}
 
export default Prototipado;