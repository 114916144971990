import React from 'react';
import { Helmet } from 'react-helmet';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import Presentacion from '../../components/Presentacion';
import Caracteristicas from '../../components/Caracteristicas';
import Video from '../../components/Video';
import Recorrido from '../../components/Recorrido';
import Prototipado from '../../components/Prototipado';
import Interiores from '../../components/Interiores';
import Ubicacion from '../../components/Ubicacion';
import Departamentos from '../../components/Departamentos';
import Fondo from "../../components/Fondo";
import Mapa from '../../components/Mapa';
import Footer from '../../components/Footer';



import Amenidades from '../../components/Amenidades';

const Home = () => {

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/fondo1.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/fondo2.webp' }
    ];

    return ( 
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <FloatingWhatsApp
                phoneNumber = '523314109154'
                accountName = 'Miralto Vista'
                statusMessage  = 'En línea'
                avatar = 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/logo.svg'
                chatMessage = '¿Estás listo para vivir conectado con la ciudad y la naturaleza? ¡Escríbenos!'
                placeholder = 'Escribe tu mensaje...'
            />
            <Presentacion />
            <Caracteristicas />
            <Video />
            <Amenidades />
            <Fondo 
                img={images[0].imageUrl}
                title='Descubre más sobre Miralto Vista' />
            <Recorrido />
            <Prototipado />
            <Interiores />
            <Ubicacion />
            <Departamentos />
            <Fondo 
                img={images[0].imageUrl}
                title='Tu depa dentro de un condominio residencial de primera categoría' />
            <Mapa />
            <Footer />
        </>
    );
}
 
export default Home;