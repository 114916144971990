import React, { useState } from 'react';
import { Flex, Text, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';

import '../styles/styled.css';

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Amenidades = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    
    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[0].images.length-1)):
        setActiveIndex(activeIndex - 1);
    };
    
    const slideNext = () => {
        activeIndex===listImages[0].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    
    };

    return ( 
        <Flex id='amenidades' className='wrapper-all-sections-carrusel-alice' flexDirection={"column"} alignItems={"center"} marginBottom={"2rem"}>
            <Flex flexDirection={"column"} alignItems={"center"}>
                <Text color={"#545454"} fontWeight={"700"} fontSize='2xl'>
                    25 Amenidades
                </Text>
            </Flex>
            <Flex className='section-carrusel-mons' justifyContent={"center"} flexDirection={"row"} width={"100%"}  alignItems={"center"}>
                <IconButton
                    icon={<ChevronLeftIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-izq'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={() => slidePrev()}
                    position="relative"
                    left={{ base: "-4", md: 40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
                <AliceCarousel
                    mouseTracking
                    animationDuration={800}
                    disableButtonsControls
                    activeIndex={activeIndex}
                    items={listImages[0].images}
                    responsive={true}
                    controlsStrategy="responsive"
                    animationType='fadeout'
                    autoPlay={true}
                    autoPlayInterval={5000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableDotsControls
                    autoHeight={false}
                    maxHeight="200px"
                />
                <IconButton
                    icon={<ChevronRightIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-drc'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={() => slideNext()}
                    position="relative"
                    left={{ base: "-6", md: -40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
            </Flex>
        </Flex>
    );
}
 
export default Amenidades;