import React from 'react';
import { Flex, Text, useMediaQuery, useTheme, Image } from '@chakra-ui/react';
import Slider from "react-slick";
import "../styles/styled.css";

const Interiores = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [isGreaterSmThanMd] = useMediaQuery(`(min-width: ${breakpoints.sm})`);
    const [isSmall] = useMediaQuery(`(max-width: 552px)`);

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot1.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot2.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot3.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot4.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot5.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot6.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot7.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot8.webp' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/interioes/prot9.webp' }
    ];

    function renderSize() {
        if (isGreaterThanMd) {
            return 4;
        }
        // else if (isGreaterMidThanMd) {
        //     return 3;
        // } 
        else if (isGreaterSmThanMd) {
            return 2;
        }
        else {
            return 1;
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: renderSize(),
        slidesToScroll: renderSize(),
        rows: isGreaterThanMd ? 1 : 3,
        activeIndex: 0
    };

    return (
        <Flex
            id='interiores'
            flexDirection={"column"}
            alignItems={"center"}
            mt={16}
            mb={isSmall ? 20 : 0}
            pb={isSmall ? 20 : 0}
        >
            <Flex textAlign={"center"} alignItems={"center"} flexDirection={"column"} width='100%' marginBottom={"2rem"}>
                <Text width={isGreaterThanMd ? "58%" : "90%"} color={"#545454"} fontWeight={"700"} fontSize='2xl'>
                    Departamentos de 3 recámaras desde 105 m²
                </Text>
                <Text width={isGreaterThanMd ? "58%" : "90%"} color={"#545454"} fontWeight={"light"} fontSize='md'>
                    Experiencias únicas desde tu propio depa
                </Text>
            </Flex>
            <Flex flexDirection={"column"} width={"75%"} marginBottom={"3rem"}>
                <Slider {...settings}>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[0].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[1].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[2].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[3].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[4].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[5].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[6].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[7].imageUrl} alt='icon' />
                    </div>
                    <div>
                        <Image className='img-deptos-md' objectFit='cover' boxSize='350px' src={images[8].imageUrl} alt='icon' />
                    </div>
                </Slider>
            </Flex>
        </Flex>
    );
}

export default Interiores;