import React from 'react';
import { Flex, useMediaQuery, useTheme, Text, Image } from '@chakra-ui/react';

const Caracteristicas = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const icons = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/departamento.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/mts.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/peso.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/icons/mapa.svg' }
    ];

    return ( 
        <Flex flexDirection={"column"} margin={"2rem 0px"} marginTop={"8rem"}>
            <Flex textAlign={"center"} justifyContent={"center"} marginBottom={"2rem"}>
                <Text width={isGreaterThanMd ? "58%" : "100%"} color={"#545454"} fontWeight={"700"} fontSize={isGreaterThanMd ? '2xl' : 'lg'}>
                    Departamentos con vistas espectaculares, 5000m² de áreas<br/> verdes y más de 25 amenidades
                </Text>
            </Flex>
            <Flex flexDirection={isGreaterThanLg ? "row" : "column"} marginBottom={"3rem"} justifyContent={"center"} alignItems={"center"}>
                <Flex>
                    <Flex marginRight={"4rem"} alignItems={"center"}>
                        <Image src={icons[0].imageUrl} w={12}/>
                        <Text marginLeft={"1rem"} fontSize='sm'>50 departamentos <br/> dentro de un coto</Text>
                    </Flex>
                    <Flex marginRight={isGreaterThanLg ? "4rem" : "0px"} alignItems={"center"}>
                        <Image src={icons[1].imageUrl} w={12}/>
                        <Text marginLeft={"1rem"} fontSize='sm'>Desde 105 m²</Text>
                    </Flex>
                </Flex>
                <Flex marginTop={isGreaterThanLg ? "0px" : "2rem"}>
                    <Flex marginRight={"4rem"} alignItems={"center"}>
                        <Image src={icons[2].imageUrl} w={12}/>
                        <Text marginLeft={"1rem"} fontSize='sm'>Más de <br />25 amenidades</Text>
                    </Flex>
                    <Flex  alignItems={"center"}>
                        <Image src={icons[3].imageUrl} w={12}/>
                        <Text marginLeft={"1rem"} fontSize='sm'>A 10 min. del centro <br/>de Zapopan</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default Caracteristicas;