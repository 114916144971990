import React, { useEffect } from 'react';
import { Flex, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import "../styles/mapa.css";

const Mapa = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    useEffect(() => {
        const script = document.createElement('script');
        script.src='//js.hsforms.net/forms/v2.js?pre=1';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "5930840",
                    formId: "40c90165-f16c-4511-b24f-a614e3202844",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);
    
    return ( 
        <>
            <Flex justifyContent={"center"}>
                <Flex flexDirection={"column"} marginBottom={"3rem"} marginTop={"1rem"} width={isGreaterThanMd?"35%":'70%'} textAlign={"center"}>
                    <Text color={"#545454"} fontSize='xl' fontWeight={"700"}>Quiero que un asesor me contacte</Text>
                </Flex>
            </Flex>
            <Flex justifyContent={"center"} id='contacto' marginBottom={"4rem"}>
                <div id="hubspotForm" style={{ width: isGreaterThanMd?"50%":'70%' }}></div>
            </Flex>
        </>
    );
}
 
export default Mapa;