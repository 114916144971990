import React from "react";
import { Button, useDisclosure, useMediaQuery, useTheme } from "@chakra-ui/react";
import ModalContacto from './ModalContacto';

const ButtonBrochure = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return (
        <>
            <Button 
                borderRadius={"18px"} 
                color={"#FFFFFF"} 
                fontWeight={"bold"}
                width={isGreaterThanMd ? "232px" : "190px"}
                fontSize={isGreaterThanMd ? "16px" : "14px"}
                backgroundColor={"#26B8CE"}
                _hover={{ bg: '#E6E6E6' }}
                onClick={onOpen}>Descargar brochure</Button>
            {isOpen ?
                <ModalContacto 
                    isOpen={isOpen} 
                    onClose={onClose}
                    title={"Descargar Brochure"}
                    thankyoupage={"thankyoupage-brochure"}
                    idForm={"a0f70341-d930-49c4-bdca-145acbc62208"} /> : null
            }
        </>
    );
}
 
export default ButtonBrochure;