import React from 'react';
import { 
    Flex,
    useMediaQuery, 
    useTheme, 
} from '@chakra-ui/react';

import ReactPlayer from 'react-player';

const Video = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const video = [
        { videoUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/video/video-miralto.mp4' }
    ];

    return ( 
        <Flex id='video' justifyContent={"center"} marginBottom={"2rem"}>
            <Flex width={isGreaterThanMd ? "50%": "80%"} marginBottom={"2rem"}>
                <ReactPlayer url={video[0].videoUrl} controls/>
            </Flex>
        </Flex>
    );
}
 
export default Video;