import React from "react";
import { useDisclosure, Button } from "@chakra-ui/react";
import ModalContacto from './ModalContacto';

const ButtonNavCita = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                bg={'#26B8CE'}
                color={'white'}
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                as='a'
                borderRadius='7px'
                display={{ base: 'none', md: 'flex' }}
                _hover={{
                    bg: '#26B8CE',
                    cursor: 'pointer'
                }}
                onClick={onOpen}
            >
                Haz tu cita
            </Button>
            {isOpen ?
                <ModalContacto
                    isOpen={isOpen}
                    onClose={onClose}
                    title={"Haz tu cita"}
                    thankyoupage={"thankyoupage-agenda-cita"}
                    idForm={"40c90165-f16c-4511-b24f-a614e3202844"} /> : null
            }
        </>
    );
}
 
export default ButtonNavCita;