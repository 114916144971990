import { Flex } from '@chakra-ui/react';

import '../styles/recorrido.css';
import '../styles/styled.css';

const images = [
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img1.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img2.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img3.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img4.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img5.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img6.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img7.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img8.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img9.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img10.webp' },
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/miralto/images/amenidades/img11.webp' }
    
];

export const listImages = [
    {
        id: 1,
        images: [
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[10].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[0].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[1].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[0].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[1].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[2].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[1].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[2].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[3].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[2].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[3].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[4].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[3].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[4].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[5].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[4].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[5].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[6].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[5].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[6].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[7].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[6].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[7].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[8].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[7].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[8].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[9].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[8].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[9].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[10].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[9].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"5rem"}>
                    <img style={{ width: "100%" }} src={images[10].imageUrl} width='100' height='100' alt='icon'/>
                </Flex>
                <Flex className='col3-mon'  backgroundImage={`linear-gradient(rgba(38, 184, 206, 0.6), rgba(38, 184, 206, 0.6)), url('${images[0].imageUrl}')`} width={"20%"} margin={"5rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
        ]
    }
];